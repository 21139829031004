import * as React from 'react';
import Heading from 'components/text/Heading';
import Button from 'components/Button';
import { useTranslation } from 'next-i18next';
import { routes } from 'utils/routes';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="NotFound min-h-[calc(100vh-180px)] sm:min-h-[calc(100vh-200px)] flex items-center justify-center flex-col">
      <Heading variant="h2">{t('notFound.pageNotFound')}</Heading>
      <Button variant="primary" className="mt-10" href={routes.shop.index()}>
        {t('notFound.keepBrowsing')}
      </Button>
    </div>
  );
};

export default NotFound;
